import { useEffect, useState } from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { Modal } from '@components/Modal';
import { PaymentType } from '@area/payments/types';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { Select } from '@components/Select';
import { Option } from '@components/Select/types';
import {
  HumansFilterRequest,
  useParticipantsHumansControllerCreateFilterHumansPaymentMutation,
} from '@api/admin/adminGeneratedApi';
import { studentGrades } from '@services/grades';
import { Checkbox } from '@components/Checkbox';
import { paymentOptions } from '../defaultValues';
import styles from './styles.module.scss';

export interface StudentsPaymentModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  humansFilterRequest: HumansFilterRequest;
}

const periods = studentGrades.map((item): Option => ({ label: item, value: item }));

export const StudentsPaymentModal = ({ openModal, setOpenModal, humansFilterRequest }: StudentsPaymentModalProps) => {
  const [create, { isLoading, isSuccess }] = useParticipantsHumansControllerCreateFilterHumansPaymentMutation();
  const { handlePushItem } = useNotifications();
  const [selectedPeriod, setSelectedPeriod] = useState<Option>(periods[0]);
  const [paymentType, setPaymentType] = useState<Option<PaymentType>>(paymentOptions[0]);
  const [onlyNewParticipants, setOnlyNewParticipants] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Выплата успешно создана' });
      setOpenModal(false);
    }
  }, [isSuccess]);

  const onClickCreate = async () => {
    try {
      if (!paymentType) return;
      await create({
        humanCreatePaymentRequest: {
          ...humansFilterRequest,
          paymentType: paymentType.value,
          period: selectedPeriod.value,
          onlyNewParticipants,
        },
      });
    } catch (e) {
      return;
    } finally {
      setOpenModal(false);
    }
  };

  const onClose = () => {
    if (isLoading) return;

    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        title="Новая выплата"
        maxWidth="sm"
        onClose={onClose}
        actions={
          <>
            <Button onClick={onClickCreate} variant="contained" disabled={isLoading}>
              Сформировать
            </Button>
            <Button onClick={onClose} variant="outlined" disabled={isLoading}>
              Отмена
            </Button>
          </>
        }>
        <div className={styles.container}>
          <span className={styles.subTitle}>Сформировать новую выплату для выбранных участников?</span>
          <div>
            <Select
              value={paymentType}
              onChange={(option) => setPaymentType(option as Option<PaymentType>)}
              options={paymentOptions}
              disabled
            />
          </div>
          <div>
            <span className={styles.periodTitle}>Период выплаты</span>
            <Select
              value={selectedPeriod}
              onChange={(option) => setSelectedPeriod(option as Option)}
              options={periods}
              sx={{ marginTop: '8px' }}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox checked={onlyNewParticipants} onChange={() => setOnlyNewParticipants((prev) => !prev)} />
            }
            label="Учитывать только новых участников"
          />
        </div>
      </Modal>
    </>
  );
};
